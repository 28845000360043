import { IMenuContent, IMenu } from '../../../_models/cms/IMenu';
import { MenuService } from './../../services/menu.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  public isCollapsed = true;

  primaryMenuContents: IMenuContent[];

  menuTemplate: string = "";

  constructor(private authService: AuthService,
    private menuService: MenuService) {
  }

  ngOnInit() {
    this.loadMenu();
  }

  loadMenu() {
    this.menuService.get(1).subscribe((response: IMenu) => {
      if (response) {
        this.primaryMenuContents = response.menuContents.filter(x => x.parentMenuContentId == null)
          .sort(this.GetSortOrder("order"));
      }
    });
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  GetSortOrder(prop) {
    return (a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }
}
