import { Injectable, Inject } from '@angular/core';
import {DataService} from "./data.service";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TransferOrderService extends DataService {
  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/transfer-orders', httpClient, toastrService);
  }

  
  export(ids: number[] = []) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.post(this.baseUrl + 'api/transfer-orders/download', ids, httpOptions)
      .pipe(catchError(this.handleError.bind(this)))
  }
}