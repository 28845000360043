import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ServiceBookService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/service-books', httpClient, toastrService);
  }

  getPresentPlace() {
    return this.httpClient.get(this.baseUrl + 'api/service-books/present-place')
    .pipe(catchError(this.handleError.bind(this)))
  }

  
  getPresentPlaceByProfileId(profileId: string) {
    return this.httpClient.get(this.baseUrl + 'api/service-books/present-place/' + profileId)
    .pipe(catchError(this.handleError.bind(this)))
  }

}