import { icons } from './_common/bootstrap-icons';

import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { I18nModule } from '../i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { ToastrModule } from 'ngx-toastr';

import { AppErrorHandler } from './_common/app-error-handler';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
import { EmployeeAuthGuard } from './services/employee-auth-guard.service';
import { SchoolAuthGuard } from './services/school-auth-guard.service';
import { RegionAuthGuard } from './services/region-auth-guard.service';

import { ApplicationStateService } from './services/application-state.service';
import { CommonService } from './services/common.service';
import { MediaFileService } from './services/media-file.service';
import { MenuService } from './services/menu.service';
import { MenuContentService } from './services/menu-content.service';
import { CmsPageService } from './services/cms-page.service';
import { EditorConfigService } from './services/angular-editor.service';
import { FAQCategoryService } from './services/faq-category.service';
import { FAQService } from './services/faq.service';
import { NoticeBoardService } from './services/notice-board.service';
import { NoticeBoardCategoryService } from './services/notice-board-category.service';
import { FeedbackService } from './services/feedback.service';
import { SanctionedPostService } from './services/sanctioned-post.service';
import { RegionService } from './services/region.service';
import { StateService } from './services/state.service';
import { DistrictService } from './services/district.service';
import { SchoolService } from './services/school.service';
import { VacantPostService } from './services/vacant-post.service';
import { ProfileService } from './services/profile.service';
import { DisabledInfoService } from './services/disabled-info.service';
import { DiseaseInfoService } from './services/disease-info.service';
import { SpouseInInfoService } from './services/spouse-in-info.service';
import { SpouseOutInfoService } from './services/spouse-out-info.service';
import { SingleWomenInfoService } from './services/single-women-info.service';
import { ServiceBookService } from './services/service-book.service';
import { TransferRequestService } from './services/transfer-request.service';
import { ProfileContactService } from './services/profile-contact.service';
import { PreferenceService } from './services/preference.service';
import { TransferOrderService } from './services/transfer-order.service';
import { ProfileThumbService } from './services/profile-thumb.service';

import { MyInputDirective } from './_directives/my-input.directive';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MyErrorComponent } from './components/my-error/my-error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LogoBarComponent } from './components/logo-bar/logo-bar.component';
import { UserBarComponent } from './components/user-bar/user-bar.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SidebarLinksComponent } from './components/sidebar-links/sidebar-links.component';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { CustomAdapter, CustomDateParserFormatter } from './_common/custom-date-formatter';
import { EmployeeViewComponent } from '../shared/components/employee-view/employee-view.component';
import { EmployeeProfileComponent } from './components/employee-profile/employee-profile.component';
import { GrievanceService } from './services/grievance.service';

@NgModule({
  declarations: [
    MyInputDirective,
    OrderByPipe,
    PaginationComponent,
    NoAccessComponent,
    NotFoundComponent,
    MyErrorComponent,
    LoaderComponent,
    UserBarComponent,
    NavBarComponent,
    FooterComponent,
    LogoBarComponent,
    MenuItemComponent,
    SidebarLinksComponent,
    SelectLanguageComponent,
    EmployeeViewComponent,
    EmployeeProfileComponent
  ],
  imports: [
    CommonModule,
    //I18nModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    AngularEditorModule,
    NgxBootstrapIconsModule.pick(icons),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      progressBar: true
    }),
  ],
  exports: [
    CommonModule,
    //I18nModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    AngularEditorModule,
    NgxBootstrapIconsModule,
    ToastrModule,

    MyInputDirective,
    OrderByPipe,
    PaginationComponent,
    NoAccessComponent,
    NotFoundComponent,
    MyErrorComponent,
    LoaderComponent,
    UserBarComponent,
    FooterComponent,
    NavBarComponent,
    LogoBarComponent,
    SidebarLinksComponent,
    SelectLanguageComponent,
    EmployeeViewComponent,
    EmployeeProfileComponent
  ],
  providers: [
    DatePipe,
    DataService,
    { provide: 'API_BASE_URL', useValue: '/' },
    AuthService,
    AuthGuard,
    AdminAuthGuard,
    RegionAuthGuard,
    SchoolAuthGuard,
    EmployeeAuthGuard,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    // {
    //   provide: LOCALE_ID,
    //   deps: [SessionService],
    //   useFactory: (sessionService) => sessionService.getLocale()
    // },
    ApplicationStateService,
    CommonService,
    EditorConfigService,
    MediaFileService,
    CmsPageService,
    MenuContentService,
    MenuService,
    NoticeBoardCategoryService,
    NoticeBoardService,
    FAQCategoryService,
    FAQService,
    FeedbackService,
    SanctionedPostService,
    VacantPostService,
    RegionService,
    StateService,
    DistrictService,
    SchoolService,
    ProfileContactService,
    ProfileService,
    DisabledInfoService,
    DiseaseInfoService,
    SpouseInInfoService,
    SpouseOutInfoService,
    SingleWomenInfoService,
    ServiceBookService,
    TransferRequestService,
    PreferenceService,
    ProfileThumbService,
    TransferOrderService,
    GrievanceService
  ]
})
export class SharedModule { }
