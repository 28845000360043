import { IMenuContent } from '../../../_models/cms/IMenu';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  @Input() child: IMenuContent;

  constructor() { }

  ngOnInit(): void {
  }

}
