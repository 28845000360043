import { Injectable, Inject } from '@angular/core';
import {DataService} from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class FAQCategoryService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/faqcategories', httpClient, toastrService);
  }


  getAllFAQCategories() {
    return this.httpClient.get(this.baseUrl + 'api/faqcategories/all')
    .pipe(catchError(this.handleError.bind(this)))
  }

}