
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DataService {
  httpClient: HttpClient;
  constructor(@Inject('API_BASE_URL') private url: string, @Inject(HttpClient) httpClient: HttpClient, @Inject(ToastrService) private toastrService: ToastrService) {
    this.httpClient = httpClient;
  }

  getAll(filter) {
    return this.httpClient.get(this.url + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  get(id) {
    return this.httpClient.get(this.url + '/' + id)
      .pipe(catchError(this.handleError.bind(this)))
  }

  create(resource) {
    return this.httpClient.post(this.url, resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  update(id, resource) {
    return this.httpClient.put(this.url + '/' + id, resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  delete(id) {
    return this.httpClient.delete(this.url + '/' + id)
      .pipe(catchError(this.handleError.bind(this)))
  }

  toQueryString(obj) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }

    return parts.join('&');
  }

  extractData(res: Response) {
    let body = res;
    return body || {};
  }


  error(error) {
    console.log(error);
    this.toastrService.error(error);
  }

  badInput(error?: any) {
    console.log(error);
    let modelStateErrors = '';
    if (error?.errors) {
      for (const key in error.errors) {
        if (error.errors[key]) {
          modelStateErrors += error.errors[key] + '\n';
        }
      }
    }
    else {
      for (const key in error) {
        if (error[key]) {
          modelStateErrors += error[key] + '\n';
        }
      }
    }
    this.toastrService.error(modelStateErrors);
  }

  forbiddenError() {
    this.toastrService.error('unauthorized access.');
    localStorage.removeItem("token");
    //window.location.href = "/account/login";
  }

  notFoundError() {
    this.toastrService.error('not found.');
  }

  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status == 400)
      throwError(this.badInput(errorResponse.error));
    else if (errorResponse.status === 404)
      throwError(this.notFoundError());
    else if (errorResponse.status == 401)
      throwError(this.forbiddenError());
    else
      throwError(this.error(errorResponse));

    return EMPTY;
  }
}