import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnChanges {
  @Input() show: boolean = false;

  constructor() { }
  ngOnChanges(): void {
    this.show = this.show;
  }

  ngOnInit() {
  }

}
