import { ISetting } from './../../_models/cms/ISetting';
import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingService extends DataService {

  private setting = new BehaviorSubject<ISetting>(null);
  public setting$ = this.setting.asObservable();

  async setSetting() {
    return await this.getCurrentSetting().toPromise();
  }

  async getSetting(): Promise<ISetting> {
    return new Promise<ISetting>((resolve, reject) => {
      if (!this.setting.getValue()) {
        this.getCurrentSetting().toPromise().then(setting => {
          this.setting.next(setting);
          resolve(setting);
        });
      }
      else {
        resolve(this.setting.getValue());
      }
    });
  }

  getCurrentSetting(): Observable<ISetting> {
    return this.httpClient.get<ISetting>(this.baseUrl + 'api/settings').pipe(
      map(response => response)
    );
  }

  async updateSetting() {
    const setting$ = await this.getCurrentSetting().toPromise();
    this.setting.next(setting$);
  }

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/settings', httpClient, toastrService);
  }
}