import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GrievanceService extends DataService {

    constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
        super(baseUrl + 'api/grievance', httpClient, toastrService);
    }
}