import { AuthService } from './../../services/auth.service';
import { ITransferRequest } from '../../../_models/transfer-sections/ITransferRequest';
import { IPreference } from '../../../_models/transfer-sections/IPreference';
import { PreferenceService } from '../../services/preference.service';
import { IServiceBook } from '../../../_models/profile-sections/IServiceBook';
import { ISingleLadyType } from '../../../_models/profile-sections/ISingleWomenInfo';
import { IProfile } from '../../../_models/account/IProfile';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.css']
})
export class EmployeeProfileComponent implements OnInit {
  @Input() profile: IProfile;
  @Input() transferRequest: ITransferRequest;
  @Input() showProfile: boolean = true;
  @Input() moreProfile: boolean = true;
  @Input() showOther: boolean = true;
  @Input() showPreference: boolean = true;
  @Input() isEdit: boolean = true;
  preferences: IPreference[];

  constructor(
    private preferenceService: PreferenceService,
    private authService: AuthService) { }

  ngOnChanges(): void {
  }

  ngOnInit(): void {
    this.getPreferences();
  }

  private getPreferences() {
    if (this.showPreference && this.transferRequest?.id) {
      this.preferenceService.getPreferencesByTransferRequest(this.transferRequest?.id)
        .subscribe((response: IPreference[]) => {
          if (response) {
            this.preferences = response;
          }
        });
    }
  }

  getSingleLadyType(index: number) {
    return ISingleLadyType[index].replace("_", " ");
  }

  getServiceBook(v: IServiceBook[]) {
    return v ? v.find(x => x.isPresent == true) : null;
  }

  showNVSOut() {
    return this.profile.spouseInInfos.length > 0;
  }

  showNVSIn() {
    return this.profile.spouseOutInfos.length > 0;
  }

  isSingleWomen() {
    return this.profile.singleWomenInfos.length > 0;
  }

  isSpouse() {
    return this.profile.spouseInInfos.length > 0 || this.profile.spouseOutInfos.length > 0;
  }

  get isAdmin(): boolean {
    return this.authService.isInRole('admin');
  }
}