import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SingleWomenInfoService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/single-women-infos', httpClient, toastrService);
  }

  logged() {
    return this.httpClient.get(this.baseUrl + 'api/single-women-infos/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getByProfileId(profileId: string) {
    return this.httpClient.get(this.baseUrl + 'api/single-women-infos/id/' + profileId)
      .pipe(catchError(this.handleError.bind(this)))
  }
}