import { Injectable, Inject } from '@angular/core';
import {DataService} from "./data.service";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NoticeBoardService extends DataService {
  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/notice-boards', httpClient, toastrService);
  }
}