import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'page',
    data: { breadcrumb: 'Page' },
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
  },
  {
    path: 'notice-board',
    data: { breadcrumb: 'Notice Board' },
    loadChildren: () => import('./notice-board/notice-board.module').then(m => m.NoticeBoardModule)
  },
  {
    path: 'faq',
    data: { breadcrumb: 'FAQ' },
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    data: { breadcrumb: 'Search' }
  },
  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'employee',
    data: { breadcrumb: 'Employee' },
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)
  },
  {
    path: 'profile',
    data: { breadcrumb: 'Profile' },
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'school',
    loadChildren: () => import('./school/school.module').then(m => m.SchoolModule),
    data: { breadcrumb: "School" }
  },
  {
    path: 'region',
    loadChildren: () => import('./region/region.module').then(m => m.RegionModule),
    data: { breadcrumb: "Region" }
  },
  {
    path: 'vacancy',
    loadChildren: () => import('./vacancy/vacancy.module').then(m => m.VacancyModule),
    data: { breadcrumb: "Vacancy" }
  },
  {
    path: 'management',
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    data: { breadcrumb: "Management" }
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: { breadcrumb: "404 Error" }
  },
  {
    path: '**',
    redirectTo: '/404',
    data: { breadcrumb: "404 Error" }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
