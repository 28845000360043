import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.css']
})
export class SelectLanguageComponent implements OnInit {

  constructor(public translate: TranslateService) { 
    translate.currentLang = 'hi';

  }

  ngOnInit(): void {
  }

}
