import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class VacantPostService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/vacant-posts', httpClient, toastrService);
  }


  export(filter) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get(this.baseUrl + 'api/vacant-posts/export' + '?' + this.toQueryString(filter), httpOptions)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getVacantPosts(filter) {
    return this.httpClient.get(this.baseUrl + 'api/vacant-posts/preferences' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
  delete(id) {
    return this.httpClient.post(this.baseUrl + 'api/vacant-posts/delete/' + id, {})
      .pipe(catchError(this.handleError.bind(this)))
  }
}
