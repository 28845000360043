import { IFinancialYear } from '../masters/IFinancialYear';
import { IMediaFile } from '../cms/IMediaFile';
import { IProfile } from '../account/IProfile';

export interface ISingleWomenInfo {
    id: number;
    financialYearId: number;
    financialYear: IFinancialYear;
    profileId: null;
    profile: IProfile;
    singleLadyType: number;
    deathCertId: number;
    deathCert: IMediaFile;
    isActive: boolean;
}

export enum ISingleLadyType {
    Widow = 1,
    Divorced,
    Unmarried
}