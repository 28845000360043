import {
    house,
    houseDoor,
    x,
    search,
    person,
    hash,
    gear,
    power,
    arrowLeftShort,
    eye,
    pencilSquare,
    pencil,
    check2Square,
    check2Circle,
    trash,
    trash2,
    personFill,
    at,
    lockFill,
    chevronRight,
    chevronLeft,
    arrowLeft,
    arrowRight,
    arrowsMove,
    calendar4,
    geoAlt,
    geoAltFill,
    map,
    questionCircle,
    chevronUp,
    chevronDown,
    list,
    telephone,
    telephoneFill,
    caretRightFill,
    caretDownFill,
    caretUpFill,
    book,
    calendar3,
    envelopeFill,
    chevronDoubleRight,
    plus,
    personCircle
} from 'ngx-bootstrap-icons';

export const icons = {
    house,
    houseDoor,
    x,
    search,
    person,
    hash,
    gear,
    power,
    arrowLeftShort,
    eye,
    pencilSquare,
    pencil,
    check2Square,
    check2Circle,
    trash,
    trash2,
    personFill,
    at,
    lockFill,
    chevronRight,
    chevronLeft,
    arrowLeft,
    arrowRight,
    arrowsMove,
    calendar4,
    geoAlt,
    geoAltFill,
    map,
    questionCircle,
    chevronUp,
    chevronDown,
    list,
    telephone,
    telephoneFill,
    caretRightFill,
    caretDownFill,
    caretUpFill,
    book,
    calendar3,
    envelopeFill,
    chevronDoubleRight,
    plus,
    personCircle
};