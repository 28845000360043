import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class PreferenceService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/preferences', httpClient, toastrService);
  }

  logged() {
    return this.httpClient.get(this.baseUrl + 'api/preferences/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getPreferences(filter) {
    return this.httpClient.get(this.baseUrl + 'api/preferences/app' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getPreferencesByTransferRequest(transferRequestId: number) {
    return this.httpClient.get(this.baseUrl + 'api/preferences/view/' + transferRequestId)
      .pipe(catchError(this.handleError.bind(this)))
  }

  up(resource) {
    return this.httpClient.post(this.baseUrl + 'api/preferences/up/' + resource.id, {})
      .pipe(catchError(this.handleError.bind(this)))
  }

  down(resource) {
    return this.httpClient.post(this.baseUrl + 'api/preferences/down/' + resource.id, {})
      .pipe(catchError(this.handleError.bind(this)))
  }
}